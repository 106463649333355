import { Injectable } from '@angular/core';
import { resourcesConfig } from '@proman/resources';
import { ACL } from '@proman/services/acl.service';
import { Entity } from '@proman/services/entity.service';

@Injectable()
export class PersonalEventService {
    eventEntity: any;

    constructor(
        private ACL: ACL,
        private Entity: Entity,
    ) {
        this.eventEntity = Entity.get('event');
    }

    canStart = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId)) && (event.status === this.eventEntity.UNSTARTED || event.status === this.eventEntity.CANCELED);
    };

    canEnd = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId)) && event.status === this.eventEntity.STARTED;
    };

    canCancel = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId)) && event.status === this.eventEntity.STARTED;
    };

    canUpdateTime = (event: any, employeeId: number) => {
        return this.isPersonalEvent(event, employeeId);
    };

    isPersonalEvent = (event: any, employeeId: number) => {
        if (event.employee) {
            return event.employee.id == employeeId;
        }

        return event.resourceBookings && event.resourceBookings
            .filter((booking: any) => {
                return booking.employee && booking.employee.id == employeeId;
            }).length > 0;
    };

}
